
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    /*align-items: center;*/
    background-color: var(--black);
    padding: 10px;
    /*font-size: 12px;*/
    /*line-height: 20px;*/
    font-family: sans-serif;
    color: var(--white);
    flex-wrap: wrap;
}

.navbar ul {
    flex-basis: 400px;
    flex-shrink: 1;
    flex-grow: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.navbar li {
    list-style-type: none;
    flex-wrap: wrap;
}


.navbar a {
    text-decoration: none;
    /*color: var(--orange);*/
    color: var(--white);
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
}


p {
    font-size: 20px;
}



/*-------------------HAMBURGER-MENU----------------------------*/

#hamburger-menu {
    background-color: var(--blue);
    position: relative;
    width: 60px;
    height: 10px;
    transition: all 0.15s ease-in-out;
}

#hamburger-menu::before,
#hamburger-menu::after {
    background-color: var(--blue);
    display: block;
    content: "";
    width: 60px;
    height: 10px;
    position: absolute;
    transition: all 0.35s ease-in-out;
}

#hamburger-menu::before {
    left: 0;
    top: -20px;
}

#hamburger-menu::after {
    left: 0;
    bottom: -20px;
}

#hamburger-menu-container {
    /*border: 1px solid red;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;

    /* shorthand voor:
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
     */
    padding: 20px 0;
}

#hamburger-menu-container:hover #menu {
    background-color: transparent;
}

#hamburger-menu-container:hover #menu::before {
    transform: rotate(45deg);
    top: 0;
}

#hamburger-menu-container:hover #menu::after {
    transform: rotate(-45deg);
    bottom: 0;
}



